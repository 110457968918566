function stripURLAndLine(s) {
    return s.replace(/.*\.(\w{2}|\w{3})\//, '').replace(/:.*/, '');
}
/**
 * Gets the file that called the resolve, resolve calls this. It's literally
 *
 * ```js
 * module.exports = function () {
 *     // see https://code.google.com/p/v8/wiki/JavaScriptStackTraceApi
 *     var origPrepareStackTrace = Error.prepareStackTrace;
 *     Error.prepareStackTrace = function (_, stack) { return stack; };
 *     var stack = (new Error()).stack;
 *     Error.prepareStackTrace = origPrepareStackTrace;
 *     return stack[2].getFileName();
 * };
 * ```
 *
 * from https://unpkg.com/resolve@1.10.0/lib/caller.js
 *
 * but it's made to work in Firefox too  (doesn't have prepareStackTrace sadly)
 */
export function getCaller() {
    var stack = new Error().stack;
    if (stack) {
        var stacks = stack.split('\n');
        return '/' + stripURLAndLine(stacks[3]);
    }
    else {
        return '';
    }
}
export function getCallSites() {
    var stack = new Error().stack;
    if (stack) {
        var stacks = stack.split('\n');
        stacks.shift();
        return stacks.map(function (s) {
            var filename = stripURLAndLine(s);
            return {
                getFileName: function () {
                    return filename;
                },
            };
        });
    }
    else {
        return [];
    }
}
